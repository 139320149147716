import React from "react";
import { Link } from "gatsby";
import { useProductsAndServicesQuery } from "../../hooks/HomePage/useProductsAndServicesQuery";
import ProductsAndServices from "../ProductsAndServices";
import TitleAndDesc from "../TitleAndDescription";

//  This is the Products and Services Area component. It sits in the home page i.e index as a child component
//  It uses the useProductsAndServicesQuery hook in hooks/HomePage/useProductsAndServicesQuery
//  It retrives a main title, main description, and each product and its details to display
//  The product/service component is mapped here 4 times

const ProductsAndServicesArea = () => {
	const data = useProductsAndServicesQuery();
	console.log("whatWeDo", data);

	const arrayWhatWeDo = data.allWpWhatWeDo.edges;
	console.log("whatWeDo array", arrayWhatWeDo);

	return (
		<div>
			<div>
				<TitleAndDesc title={"Products & Services"} desc={""} />
			</div>
			<div>
				{arrayWhatWeDo.map((whatWeDoItem, idx) => {
					return (
						<ProductsAndServices
							key={idx}
							image={whatWeDoItem.node.featuredImage.node.gatsbyImage}
							title={whatWeDoItem.node.title}
							description={whatWeDoItem.node.excerpt}
							link={whatWeDoItem.node.uri}
						/>
					);
				})}
			</div>
			<button>
				<Link to="/whatwedo">Learn More</Link>
			</button>
		</div>
	);
};

export default ProductsAndServicesArea;

import React from "react";
import { useWhoWeAreQuery } from "../../hooks/HomePage/useWhoWeAreQuery";
import { getImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";

//   This is the WhoWeAre component. It sits in the home page i.e index as a child component
//   It uses the useWhoWeAreQuery hook in hooks/HomePage/useWhoWeAreQuery
//  It retrives an image, whoWeAreTextPt1, whoWeAreTextPt2 and whoWeAreTitle

const WhoWeAre = () => {
	const data = useWhoWeAreQuery();
	console.log(data);

	const imageData = getImage(data.wpPage.whoWeAre.whoWeAreImage.localFile);
	console.log(imageData);

	return (
		<div>
			<h1>{data.wpPage.whoWeAre.whoWeAreTitle}</h1>
			<div>
				<p>{data.wpPage.whoWeAre.whoWeAreTextPt1}</p>
			</div>
			<GatsbyImage image={imageData} alt="rubix-cube" />
			<div>
				<p>{data.wpPage.whoWeAre.whoWeAreTextPt2}</p>
			</div>
		</div>
	);
};

export default WhoWeAre;

import React from "react";
import { useInsightAreaQuery } from "../../hooks/HomePage/useInsightAreaQuery";
import Insight from "../Insight";
import TitleAndDesc from "../TitleAndDescription";

//  This is the Insight Area component. It sits in the home page i.e index as a child component
//  It uses the useInsightAreaQuery hook in hooks/HomePage
//  It retrives a main title, main description, and each insight and its details to display
//  The Insight component is mapped here 3 times

const InsightArea = () => {
	const data = useInsightAreaQuery();
	console.log("Insights", data);

	return (
		<div>
			<TitleAndDesc title={"Insights"} />
			<div>
				{new Array(3).fill("").map((element, i) => (
					<Insight
						key={i}
						tag={data.allWpPost.edges[i].node.tags.nodes.map(
							(name, i, arr) => `${name.name} ${i !== arr.length - 1 ? "," : ""} `
						)}
						image={data.allWpPost.edges[i].node.featuredImage.node.gatsbyImage}
						title={data.allWpPost.edges[i].node.title}
						excerpt={data.allWpPost.edges[i].node.excerpt}
						link={data.allWpPost.edges[i].node.uri}
					/>
				))}
			</div>
		</div>
	);
};

export default InsightArea;

import React from "react"
import { getImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { useHeroQuery } from "../../hooks/HomePage/useHeroQuery"

//   This is th home hero banner
// it has an image, hero title and hero text
//   it uses the useHeroQuery hook in hooks/HomePage/useHeroQuery

const Hero = () => {
  const data = useHeroQuery()
  console.log(data)

  const imageData = getImage(data.wpPage.heroImage.heroimage.localFile)

  return (
    <section>
      <div>
        {/* text on left hand side */}
        <div>
          <h1>{data.wpPage.heroImage.herotexttitle}</h1>
          <p>{data.wpPage.heroImage.herotext}</p>
        </div>
        {/* image on right hand side  */}
        <GatsbyImage image={imageData} alt="hero-image" />
      </div>
    </section>
  )
}

export default Hero

import { useStaticQuery, graphql } from "gatsby"

export const useProductsAndServicesQuery = () => {
  const data = useStaticQuery(graphql`
    {
      wp(readingSettings: { postsPerPage: { eq: 4 } }) {
        id
      }
      allWpWhatWeDo {
        edges {
          node {
            uri
            title
            featuredImage {
              node {
                gatsbyImage(width: 300)
              }
            }
            excerpt
            tags {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  `)
  return data
}

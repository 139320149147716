import React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import WhoWeAre from "../components/WhoWeAre";
import Seo from "../components/Seo";
import ProductsAndServicesArea from "../components/ProductsAndServicesArea";
import ExploreArea from "../components/ExploreArea";
import InsightArea from "../components/InsightArea";

const IndexPage = () => (
	<Layout>
		<Seo title="Home" />
		<Hero />
		<WhoWeAre />
		<ProductsAndServicesArea />
		<InsightArea />
		<ExploreArea />
	</Layout>
);

export default IndexPage;

import { useStaticQuery, graphql } from "gatsby";

export const useHeroQuery = () => {
	const data = useStaticQuery(graphql`
		query {
			wpPage(databaseId: { eq: 28 }) {
				heroImage {
					herotext
					herotexttitle
					heroimage {
						localFile {
							childImageSharp {
								gatsbyImageData
							}
						}
					}
				}
			}
		}
	`);
	return data;
};

import { useStaticQuery, graphql } from "gatsby";

export const useWhoWeAreQuery = () => {
	const data = useStaticQuery(graphql`
		query {
			wpPage(databaseId: { eq: 28 }) {
				id
				whoWeAre {
					whoWeAreTitle
					whoWeAreTextPt2
					whoWeAreTextPt1
					whoWeAreImage {
						localFile {
							childImageSharp {
								gatsbyImageData
							}
						}
					}
				}
			}
		}
	`);
	return data;
};

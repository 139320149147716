import React from "react";
import { Link } from "gatsby";
import { useExploreQuery } from "../../hooks/Explore/useExploreQuery";
import Explore from "../Explore";
import TitleAndDesc from "../TitleAndDescription";
//  This is the Explore Area component. It sits in the home page i.e index as a child component
//  It uses the useExploreArea hook in hooks/HomePage
//  It retrives a main title, main description, and each explore item and its details to display
//  The explore component is mapped here 4 times

const ExploreArea = () => {
	const data = useExploreQuery();
	console.log("Explore data", data);
	const ArrayOfExploreItems = data.allWpExplore.edges;
	console.log("array of explore items", ArrayOfExploreItems);

	return (
		<div>
			<div>
				<TitleAndDesc
					title={"Explore"}
					desc={
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"
					}
				/>
			</div>

			<div>
				{ArrayOfExploreItems.map((item, idx) => {
					return (
						<Explore
							key={idx}
							title={item.node.title}
							description={item.node.excerpt}
							button1={item.node.exploreLinks.button1}
							button2={item.node.exploreLinks.button2}
							button1label={item.node.exploreLinks.button1label}
							button2label={item.node.exploreLinks.button2label}
							image={item.node.featuredImage.node.gatsbyImage}
						/>
					);
				})}
			</div>
			<button>
				<Link to="/explore">Learn More</Link>
			</button>
		</div>
	);
};

export default ExploreArea;

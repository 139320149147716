import { useStaticQuery, graphql } from "gatsby"

export const useInsightAreaQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Insights" } } } }
        }
        limit: 3
      ) {
        edges {
          node {
            id
            title
            excerpt
            uri
            featuredImage {
              node {
                gatsbyImage(width: 300)
              }
            }
            tags {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  `)
  return data
}

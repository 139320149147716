import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

//  This is the Explore component. It sits in the explore area component as a child
//  This single component gets used 4 times
// The data is sent through from the eplore area component as image, title, description

const Explore = ({ title, description, button1, button1label, button2, button2label, image }) => {
	return (
		<div>
			<h1>{title}</h1>
			<p dangerouslySetInnerHTML={{ __html: description }} />
			<GatsbyImage image={image} alt="Explore-image" />
			{/* if the 2nd label is empty only show one button, otherwise show 2 buttons */}
			{button2label == null ? (
				<button>
					<a href={button1}>{button1label}</a>
				</button>
			) : (
				<div>
					<button>
						{" "}
						<a href={button1}>{button1label}</a>
					</button>
					<br></br>
					<button>
						{" "}
						<a href={button2}>{button2label}</a>
					</button>
				</div>
			)}
		</div>
	);
};

export default Explore;

import { useStaticQuery, graphql } from "gatsby"

export const useExploreQuery = () => {
  const data = useStaticQuery(graphql`
    {
      allWpExplore {
        edges {
          node {
            id
            title
            uri
            excerpt
            exploreLinks {
              button1
              button1label
              button2
              button2label
            }
            featuredImage {
              node {
                gatsbyImage(width: 300)
              }
            }
          }
        }
      }
    }
  `)
  return data
}

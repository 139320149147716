import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

//  This is the Products and Services component. It sits in the products and services area component as a child
//  This single component gets used 4 times
// The data is sent through from the products and services area component as image, title, description

const ProductsAndServices = ({ image, title, description, link }) => {
	return (
		<div>
			<h1>{title}</h1>
			<p dangerouslySetInnerHTML={{ __html: description }} />
			<GatsbyImage image={image} alt="product-image" />
			<button>
				<Link to={`/whatWeDo${link}`}>Learn More</Link>
			</button>
		</div>
	);
};

export default ProductsAndServices;

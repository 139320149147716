import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

//  This is the Insight component. It sits in the Insights area component as a child
//  This single component gets used 3 times on the home page
// The data is sent through from the insight area component as image, title, excerpt

const Insight = ({ image, title, excerpt, tag, link }) => {
	return (
		<div>
			<p>{tag}</p>
			<GatsbyImage image={image} alt="insight-image" />
			<h1>{title}</h1>
			<p dangerouslySetInnerHTML={{ __html: excerpt }} />
			<button>
				<Link to={`/insights${link}`}>Read More</Link>
			</button>
		</div>
	);
};

export default Insight;
